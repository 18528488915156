import * as React from 'react'
import { useEffect, useState } from 'react'

import Layout from 'layouts/main'
import fetch from 'node-fetch'
import TrusteeAndMentor from '../components/about/trustee&mentor'
import { config } from '../config'
import BecomeTrusteeSection from '../components/home/becomeTrusteeSection'

const CommunityPage = () => {
  const [team, setTeam] = useState({ deanAndFaculty: [], managingTeam: [] })
  const [mentors, setMentors] = useState([])

  useEffect(() => {
    fetch(`${config.API_BASE_URL}/team/forAbout`).then(response => {
      response.json().then(team => {
        setTeam({
          deanAndFaculty: team.deanAndFaculty,
          managingTeam: team.managingTeam,
        })
      })
    })

    fetch(`${config.API_BASE_URL}/users/subscribed`).then(response => {
      response.json().then(team => {
        setMentors(team)
      })
    })
  }, [])

  return (
    <Layout title="Community">
      <BecomeTrusteeSection />
      {mentors.length > 0 && <TrusteeAndMentor members={mentors} />}
    </Layout>
  )
}

export default CommunityPage
